<template>
  <div id="dyp-side-menu">
    <v-list dense rounded>
      <v-list-item link v-for="link in links" :key="link.name" @click="goTo(link.name)">
        <v-list-item-content>
          <v-list-item-title>
            {{link.text}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'DypSideMenu',
  data: () => ({
    selectedItem: 1,
    links: [
      {
        text: 'Teams',
        name: 'TeamListRoute',
        exact: true,
      },
      {
        text: 'Individuals',
        name: 'MemberListRoute',
        exact: true,
      },
    ],
  }),
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.accessToken
    },
  },
  methods: {
    goTo(name) {
      this.$router.push({ name })
    },
  },
}
</script>

<style lang="sass">
#dyp-side-menu

.theme--dark.v-application
  #side-menu
    background-color: #1E1E1E

.theme--light.v-application
  #side-menu
    background-color: #FFFFFF
    box-shadow: 1px 3px 14px rgba(0,0,0,0.2)
</style>