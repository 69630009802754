<template>
  <div class="team-create">
    <v-row>
      <v-col>
        <h1>
          Edit Team
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="team && charities">
      <v-col>
        <v-form @submit.prevent="saveTeam">
          <v-row>
            <v-col>
              <v-text-field
                label="Team Name"
                v-model="name"
                outlined
              />
            </v-col>
            <v-col>
              <v-select
                v-model="charityId"
                label="Charity"
                outlined
                :items="charities"
                item-value="id"
                item-text="name"
               />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field prefix="$" outlined label="Donation Total" type="number" v-model="donationTotal" />
            </v-col>
            <v-col>
              <v-select
                v-model="classPledge"
                label="Pledged Classes"
                outlined
                :items="pledgeOptions"
                item-value="value"
                item-text="text"
               />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right pb-10">
              <v-btn type="submit" color="primary" depressed large>
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-divider class="py-5" />
    </v-row>
    <v-row>
      <v-col cols="8">
        <h2>Team Members</h2>
      </v-col>
      <v-col cols="4" class="text-right">
        <v-btn @click="openTeamMemberDialog" color="primary" :disabled="members && members.length >= 5" depressed large>Add Team Member</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-if="members"
          :options="{ itemsPerPage: -1 }"
          sort-by="createdAt"
          :headers="headers"
          :items="members">
          <template v-slot:item.isCaptain="{ item }">
            <v-checkbox v-model="item.isCaptain" @change="updateIsCaptain($event, item.id)"></v-checkbox>
          </template>
          <template v-slot:item.lastName="{ item }">
            <span v-if="item">{{ item.firstName }} {{ item.lastName }}</span>
          </template>
          <template v-slot:item.totalClasses="{ item }">
            <span>{{ item.totalClasses }}</span>
          </template>
          <template v-slot:item.marianaTekId="{ item }">
            <span>{{ item.marianaTekId }}</span>
          </template>
          <template v-slot:item.isConfirmed="{ item }">
            <v-icon v-if="item.isConfirmed">mdi-check-bold</v-icon>
          </template>
          <template v-slot:item.phone="{ item }">
            <span>{{ item.phone }}</span>
          </template>
          <template v-slot:item.id="{ item }">
            <v-btn v-if="item" class="mr-2" x-small color="primary" depressed :loading="sendSMSPending" :disabled="sendSMSPending || item.smsSent" @click="sendConfirmation($event, item.id)">Send SMS</v-btn>
            <v-btn v-if="item" x-small color="error" :disabled="isMemberRemovePending" :loading="isMemberRemovePending" depressed @click="removeTeamMember($event, item.id)">
              Remove
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog :value="teamMemberDialog" max-width="400">
      <v-card class="pa-7">
        <v-card-text>
          <v-form>
            <v-text-field hide-details label="Mariana Tek ID" v-model="marianaTekId" outlined />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col cols="4">
            <v-btn @click="cancel" depressed color="gray" block>Cancel</v-btn>
          </v-col>
          <v-col cols="8">
            <v-btn @click="addTeamMember" :disabled="isAddLoading" :loading="isAddLoading" color="primary" depressed block>Add Team Member</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :value="showError" timeout="4000" color="error">This user already belongs to a team.</v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TeamEdit',

  data() {
    return {
      name: null,
      charityId: null,
      classPledge: null,
      donationTotal: 0,
      pledgeOptions: [
        { text: 80, value: 80 },
        { text: 100, value: 100 },
        { text: 120, value: 120 },
      ],
      headers: [
        { text: 'Captain', value: 'isCaptain', sortable: false },
        { text: 'Name', value: 'lastName' },
        { text: 'Classes Taken', value: 'totalClasses' },
        { text: 'Mariana Account', value: 'marianaTekId' },
        { text: 'Confirmed', value: 'isConfirmed' },
        { text: 'Phone', value: 'phone' },
        { text: '', value: 'id' }
      ],
      teamMemberDialog: false,
      marianaTekId: null,
      isAddLoading: false,
      sendSMSPending: false,
      showError: false,
    }
  },

  computed: {
    ...mapGetters({
      'charities': 'charity/list',
    }),
    team() {
      return this.$store.state.team.keyedById[this.$route.params.id]
    },
    members() {
      return this.team.members
    },
    isMemberRemovePending() {
      return this.$store.state['member'].isRemovePending
    },
  },

  methods: {
    async getTeam() {
      await this.$store.dispatch('team/get', this.$route.params.id)
        .then(res => {
          this.name = res.name,
          this.charityId = res.charityId,
          this.classPledge = res.classPledge
          this.donationTotal = res.donationTotal
        })
    },
    async getCharities() {
      await this.$store.dispatch('charity/find')
    },
    async saveTeam() {
      await this.$store.dispatch('team/patch', [this.team.id, {
        name: this.name,
        classPledge: this.classPledge,
        charityId: this.charityId,
        donationTotal: this.donationTotal
      }])
    },
    openTeamMemberDialog() {
      this.teamMemberDialog = true
    },
    closeTeamMemberDialog() {
      this.marianaTekId = null
      this.teamMemberDialog = false
    },
    async addTeamMember() {
      if (this.marianaTekId !== null) {
        this.isAddLoading = true
        const isCaptain = (this.members?.length === 0) ? true : false
        await this.$store.dispatch('member/create', {
          marianaTekId: this.marianaTekId,
          teamId: this.$route.params.id,
          isCaptain,
        }).then(async () => {
          await this.getTeam()
          this.closeTeamMemberDialog()
          this.isAddLoading = false
        }).catch(err => {
          console.log(err)
          this.showError = true
          setTimeout(() => {
            this.showError = false
          }, 4000)
          this.isAddLoading = false
        })
      }
    },
    cancel() {
      this.closeTeamMemberDialog()
    },
    async removeTeamMember(event, id) {
      await this.$store.dispatch('member/remove', id)
        .then(async () => {
          await this.getTeam()
        })
    },
    async sendConfirmation(event, id) {
      this.sendSMSPending = true
      await this.$store.dispatch('send-confirmation/get', id)
        .then(async (res) => {
          await this.getTeam();
          this.sendSMSPending = (res) ? true: false;
        })
        .catch(err => {
          console.log(err)
        });
    },
    async updateIsCaptain(value, id) {
      await this.$store.dispatch('member/patch', [id, {
        isCaptain: value,
      }])
    }
  },

  beforeMount() {
    this.getTeam()
    this.getCharities()
  }
}
</script>

<style lang="scss">

</style>