<template>
  <div class="team-create">
    <v-row>
      <v-col>
        <h1>
          Create Team
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form @submit.prevent="createTeam">
          <v-row>
            <v-col>
              <v-text-field
                label="Team Name"
                v-model="name"
                outlined
              />
            </v-col>
            <v-col>
              <v-select
                v-model="charityId"
                label="Charity"
                outlined
                :items="charities"
                item-value="id"
                item-text="name"
               />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field prefix="$" outlined label="Donation Total" type="number" v-model="donationTotal" />
            </v-col>
            <v-col>
              <v-select
                v-model="classPledge"
                label="Pledged Classes"
                outlined
                :items="pledgeOptions"
                item-value="value"
                item-text="text"
               />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn type="submit" color="primary" depressed large>
                Create Team
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TeamCreate',

  data() {
    return {
      name: null,
      charityId: null,
      classPledge: null,
      donationTotal: 0,
      pledgeOptions: [
        { text: 80, value: 80 },
        { text: 100, value: 100 },
        { text: 120, value: 120 },
      ]
    }
  },

  computed: {
    ...mapGetters({
      'charities': 'charity/list'
    })
  },

  methods: {
    async getCharities() {
      await this.$store.dispatch('charity/find')
    },
    async createTeam() {
      await this.$store.dispatch('team/create', {
        dypId: 1,
        name: this.name,
        classPledge: this.classPledge,
        charityId: this.charityId,
        donationTotal: this.donationTotal
      }).then((res) => {
        this.$router.push({ name: 'TeamEditRoute', params: { id: res.id } })
      })
    }
  },

  beforeMount() {
    this.getCharities()
  }
}
</script>

<style lang="scss">

</style>