<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4">
          <v-col cols="12">
            <h1>Yo</h1>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardPage',

  mounted() {
    if (this.$store.state.auth.payload.user.firstName === 'RaEmployee') this.$router.push({ name: 'IntroOffers5DayRoute' })
    if (this.$store.state.auth.payload.user.firstName === 'DypCzar') this.$router.push({ name: 'TeamListRoute' })
  }
}
</script>

<style lang="scss">

</style>