<template>
  <div class="team-list">
    <v-row justify="end">
      <v-col cols="2">
        <v-btn :to="{ name: 'TeamCreateRoute' }" color="primary" depressed large block>
          Create Team
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-if="teams"
          :options="{ itemsPerPage: -1 }"
          sort-by="createdAt"
          :headers="headers"
          :items="teams">
          <template v-slot:item.name="{ item }">
            <span v-if="item">{{ item.name }}</span>
          </template>
          <template v-slot:item.charity.name="{ item }">
            <span>{{ item.charity.name }}</span>
          </template>
          <template v-slot:item.members="{ item }">
            <span>{{ item.members.length }}</span>
          </template>
          <template v-slot:item.classPledge="{ item }">
            <span>{{ item.classPledge }}</span>
          </template>
          <template v-slot:item.id="{ item }">
            <v-btn v-if="item" x-small color="success" depressed @click="editTeam($event, item.id)">
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TeamList',

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Charity',
          value: 'charity.name',
        },
        {
          text: 'Members',
          value: 'members.length'
        },
        {
          text: 'Classes Pledged',
          value: 'classPledge'
        },
        {
          text: '',
          value: 'id'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      'teams': 'team/list'
    }),
  },

  methods: {
    async getTeams() {
      await this.$store.dispatch('team/find', {
        query: {
          $limit: 1000,
        }
      })
    },
    editTeam(event, id) {
      this.$router.push({ name: 'TeamEditRoute', params: { id } })
    }
  },

  beforeMount() {
    this.getTeams()
  }
}
</script>

<style lang="scss">

</style>