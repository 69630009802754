var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team-list"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"to":{ name: 'TeamCreateRoute' },"color":"primary","depressed":"","large":"","block":""}},[_vm._v(" Create Team ")])],1)],1),_c('v-row',[_c('v-col',[(_vm.teams)?_c('v-data-table',{attrs:{"options":{ itemsPerPage: -1 },"sort-by":"createdAt","headers":_vm.headers,"items":_vm.teams},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()]}},{key:"item.charity.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.charity.name))])]}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.members.length))])]}},{key:"item.classPledge",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.classPledge))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"x-small":"","color":"success","depressed":""},on:{"click":function($event){return _vm.editTeam($event, item.id)}}},[_vm._v(" Edit ")]):_vm._e()]}}],null,false,2915839297)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }