<template>
  <v-app id="ecco-admin" :class="{ 'authenticated': showSideMenu }">
    <top-bar v-if="!isTeacherClassRoute"/>

    <side-menu v-if="showSideMenu" />

    <v-main id="main" class="main-bg">
      <v-container fluid>
        <v-row class="justify-center">
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from '@/components/topBar'
import SideMenu from '@/components/sideMenu'

export default {
  components: {
    TopBar,
    SideMenu,
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.accessToken
    },
    isTeacherClassRoute() {
      return this.$route.name === 'TeacherClassConfirmRoute'
    },
    isCRMUser() {
      return (this.$store.state.auth.payload.user.firstName === 'RaEmployee')
    },
    isDypCzar() {
      return (this.$store.state.auth.payload.user.firstName === 'DypCzar')
    },
    showSideMenu() {
      if (this.isAuthenticated) {
        if (this.isCRMUser) return false;
        if (this.isDypCzar) return false;
        return true;
      } else {
        if (this.isTeacherClassRoute) return false;
        return false;
      }
    },
    showTopBar() {
      if (this.isAuthenticated && !this.isCRMUser) return true;
      if (this.isAuthenticated && this.isCRMUser) return false;
      return true;
    }
  },
}
</script>

<style lang="sass">
@import url("https://use.typekit.net/gux3riu.css")

.text--center
  text-align: center

.authenticated #main
  margin-left: 240px
  width: calc(100vw - 240px)
</style>