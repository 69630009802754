<template>
  <div class="member-list">
    <v-row>
      <v-col>
        <v-data-table
          v-if="members"
          :options="{ itemsPerPage: -1 }"
          sort-by="createdAt"
          :headers="headers"
          :items="members">
          <template v-slot:item.lastName="{ item }">
            <span v-if="item">{{ item.firstName }} {{ item.lastName }}</span>
          </template>
          <template v-slot:item.id="{ item }">
            <span @click="goTo({ name: 'TeamEditRoute', params: { id: item.team.id } })">{{  item.team.name }}</span>
          </template>
          <template v-slot:item.totalClasses="{ item }">
            <span>{{ item.totalClasses }}</span>
          </template>
          <template v-slot:item.marianaTekId="{ item }">
            <span>{{ item.marianaTekId }}</span>
          </template>
          <template v-slot:item.isConfirmed="{ item }">
            <v-icon v-if="item.isConfirmed">mdi-check-bold</v-icon>
          </template>
          <template v-slot:item.phone="{ item }">
            <span>{{ item.phone }}</span>
          </template>
          <template v-slot:item.id="{ item }">
            <v-btn v-if="item" class="mr-2" x-small color="primary" depressed :loading="sendSMSPending" :disabled="sendSMSPending || item.smsSent" @click="sendConfirmation($event, item.id)">Send SMS</v-btn>
            <v-btn v-if="item" x-small color="error" :disabled="isMemberRemovePending" :loading="isMemberRemovePending" depressed @click="removeTeamMember($event, item.id)">
              Remove
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MemberList',

  data() {
    return {
      headers: [
        { text: 'Name', value: 'lastName' },
        { text: 'Team', value: 'team.name' },
        { text: 'Classes Taken', value: 'totalClasses' },
        { text: 'Mariana Account', value: 'marianaTekId' },
        { text: 'Confirmed', value: 'isConfirmed' },
        { text: 'Phone', value: 'phone' },
        { text: '', value: 'id' }
      ],
      sendSMSPending: false,
    }
  },

  computed: {
    ...mapGetters({
      'members': 'member/list'
    }),

    membersLoading() {
      return this.$store.state['member'].isFindLoading
    },

    isMemberRemovePending() {
      return this.$store.state['member'].isRemovePending
    },
  },

  methods: {
    async getMembers() {
      this.$store.dispatch('member/find', {
        query: {
          dypId: 1,
          $limit: 1000,
        }
      })
    },
    async sendConfirmation(event, id) {
      this.sendSMSPending = true
      await this.$store.dispatch('send-confirmation/get', id)
        .then(async (res) => {
          await this.getMembers();
          this.sendSMSPending = (res) ? true: false;
        })
        .catch(err => {
          console.log(err)
        });
    },
    async removeTeamMember(event, id) {
      await this.$store.dispatch('member/remove', id)
        .then(async () => {
          await this.getMembers()
        })
    },
    goTo(route) {
      this.$router.push(route)
    }
  },

  beforeMount() {
    this.getMembers()
  }
}
</script>

<style lang="scss">

</style>