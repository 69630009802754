var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member-list"},[_c('v-row',[_c('v-col',[(_vm.members)?_c('v-data-table',{attrs:{"options":{ itemsPerPage: -1 },"sort-by":"createdAt","headers":_vm.headers,"items":_vm.members},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","color":"primary","depressed":"","loading":_vm.sendSMSPending,"disabled":_vm.sendSMSPending || item.smsSent},on:{"click":function($event){return _vm.sendConfirmation($event, item.id)}}},[_vm._v("Send SMS")]):_vm._e(),(item)?_c('v-btn',{attrs:{"x-small":"","color":"error","disabled":_vm.isMemberRemovePending,"loading":_vm.isMemberRemovePending,"depressed":""},on:{"click":function($event){return _vm.removeTeamMember($event, item.id)}}},[_vm._v(" Remove ")]):_vm._e()]}},{key:"item.totalClasses",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.totalClasses))])]}},{key:"item.marianaTekId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.marianaTekId))])]}},{key:"item.isConfirmed",fn:function(ref){
var item = ref.item;
return [(item.isConfirmed)?_c('v-icon',[_vm._v("mdi-check-bold")]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.phone))])]}}],null,false,2829375136)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }