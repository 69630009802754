<template>
  <div class="dyp-main-page">
    <v-main class="lighten-3">
      <v-container>
        <v-row>
          <v-col cols="2" v-if="true">
            <dyp-side-menu />
          </v-col>
          <v-col cols="10">
            <v-row>
              <v-col>
                <h1>Dedicate Your Practice 2024</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-sheet elevation="1" class="pa-4 mb-6">
                  <v-col cols="12" class="pt-5">
                    <router-view></router-view>
                  </v-col>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import dypSideMenu from '../../components/dypSideMenu.vue'
export default {
  components: { dypSideMenu },
  name: 'DypMainPage',

  data() {
    return {
      
    }
  },

  computed: {
    
  },

  methods: {
    
  }
}
</script>